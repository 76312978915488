@import './theme/variables.scss';

body {
  // font-family: $fontFamily;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
}
