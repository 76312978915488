/* General */
$primaryColor: #2caae2 !default;
$primaryColorDark: #008CFF;
$secondaryColor: #0fd2ed;
$fontFamily: "sofia-pro";
$primaryFontColor: #ffffff;

/* Root Layout */
$rootLayoutPrimaryBg: transparent linear-gradient(0deg, #04296a 0%, #45dafc 100%) 0% 0% no-repeat padding-box;
$rootLayoutSecondaryBg: #d7f2fb 0% 0% no-repeat padding-box;
$camerPageBlackTheme: #000000;

/* Main Layout */
$mainLayoutPrimaryBg: transparent linear-gradient(0deg, #04296a 0%, #45dafc 100%) 0% 0% no-repeat padding-box;
$mainLayoutSecondaryBg: #d7f2fb 0% 0% no-repeat padding-box;
$mainLayoutPrimaryHeaderBg: #00000000;
$mainLayoutSecondaryHeaderBg: #d7f2fb;
$mainLayoutDefaultBg: #fbf6f8;
$primaryFooterFontColor: #d8d8da;
$secondaryFooterFontColor: #707070;

/* Plain Layout */
$plainLayoutHeaderColor: #2caae2;
$plainLayoutFooterBackground: #fff;
$plainLayoutFooterColor: #707070;

/* colors */
$lightBlack: #1f1f1f;
$transparent: transparent;
$lightGrey: #fafafa;
$lighterGrey: #eeeeee;
$black: #000000;
$lighterBlack: #282829;
$brownGrey: #71757a;
$errorRed: #E22C2C;
$light-gray-color: #dad6ce;
$blackLighter: #393838;
$creamColor: #f1f1f1;
$successGreen: #8eeb90;
$failRed: #e60225;
$grayWhite: #b9cbe5;
$greenBorder: #A3D9CC;
$successText: #136753;
$errorText: #D91E36;
$errorBackGround: #F7D2D7;
$successBackGround: #CDEDE5;
$lineBackGround: #3ACE01;
$zaloBackGround: #0180C7;
$hintTextColor: #6F7175;
$placeHolderColor: #B8B8B5;
$secondaryHintColor: #949494;
$firstObtHeader: #04296a;
$cameraBackGround: #1C1C1C;
$lightRed: #FF0000;
$borderColor: #AAABAD;
$optionsColor: #B4B4B4; 
$selectBoxBackground: #d9f7fe;
$drawerBackGroundColor: #FFFFFE;
$grayText: #86868B;
$borderLineOne: #D2D2D7;
$borderLineTwo: #E6E6E6;
$subHeader: #6E6E73;
$successfulText: #09A490;
$hintRed: #DC334B;
$lightIvory: #FFFFF2;

/* OBT 4 colors */
$obt4primaryBlue: #45dafc;
$obt4textColor: #949494;
$obt4hintColor: #6f7175;
$obt4StatusBgColor: #E6E6E6;
$obt4ErrorStatusBgColor: #F53D4F;
$obt4SuccessStatusBgColor: #23BF9A;
$progressLineColor: #C0E6F6;
$progressCircle: #2CAAE2;
$boxBorderColor: #EBEBEB;
$secondaryPlaceHolder: #8D8E90;
$secondaryButtonText: #999999;
$blackOptions: #152348;
$thankYouText: #2C3F78;
$instructionBox: #5B5B5B;
$instructionTitle: #2daae2;
$overlayBackground: rgba(198, 198, 198, 0.95);
$overlayBackground: rgba(198, 198, 198, 0.95);
$overlayBackgroundNew: rgba(255, 255, 255, 0.75);
$qrCodeCardBackground: rgba(35, 34, 34, 0.75);
$qrCodeOverlayBackground: rgba(35, 34, 34, 0.9);

$loaderBackground: rgba(40, 40, 41, 0.75);
$errorBackground:  #FFF5F5;
$InputBackground: #E9F6FC;
$inputPlaceholder:  #B1B1B4;
$underAgeHeaderBackground: #FAFAFABF;

/* spin the wheel colors*/
$darkYellowColor: #f7d379;
$textBorderColor: #feb99b;
$offerTextColor: #fdcd8d;
$popUpBackgroundColor: #f24c8f;
$userNameColor: #43527c;
$lightYellow: #eeff41;
