.error-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: sofia-pro;
}
.error-page-image {
  width: 400px;
  height: auto;
  padding-top: 80px;
}
.error-page-heading {
  margin-top: 40px;
  font-size: 28px;
  line-height: 42px;
  font-weight: 400;
  color: #282829;
}
.error-page-subheading {
  letter-spacing: 0px;
  color: #282829;
  font-size: 20px;
  line-height: 26px;
  margin-top: 40px;
  font-weight: 400;
}
.error-page-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.error-page-submit-button{
  display: flex;
  justify-content: center;
  height: 40px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  span{
    line-height: 19px;
  }
}

@media screen and (max-width: 624px) {
  .error-page-image {
    width: auto;
    height: 184px;
    padding-top: 40px;
  }
  .error-page-heading {
    margin-top: 32px;
    margin-right: 19px;
  }
  .error-page-subheading {
    width: 327px;
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .error-page-submit-button{
    width:343px;
    span {
      line-height: 22px;
    }
  }
}
